#nprogress {
  .bar {
    background: @primary-color;
  }
  .peg {
    box-shadow: 0 0 10px @primary-color, 0 0 5px @primary-color;
  }
  .spinner-icon {
    border-top-color: @primary-color;
    border-left-color: @primary-color;
  }
}

#splash-logo {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-image: url('./public/assets/logo-invert.png');
  background-size: 168px 32px;
  background-repeat: no-repeat;
  background-position: center center;
  animation: fadein 2s;
}

#splash-error {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  font-family: Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-variant: tabular-nums;
  font-size: 14px;
  color: rgba(0,0,0,.65);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@primary-color: #6c63ff;@primary-color-fade: #968cff;@accent-color: #f58534;@layout-header-background: #404040;@menu-dark-submenu-bg: #333;